import { Api } from '@/services/apiService';

class portCallPCR {
    static create(data) {
        return Api.post(`/pcr/port_call`, data, {
            headers: { accept: 'application/json, text/plain, */*', 'content-type': 'application/json;charset=UTF-8' },
        });
    }
}

export default portCallPCR;
