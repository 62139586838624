import { statesIds } from './locationStatesInfoWithPilotObject';

export const badStatesIds = [
    ...statesIds,
    'DepartureAnchoringOp_Commenced',
    'DepartureAnchoringOp_Completed',
    'DepartureAnchoringOp_Denied',
    'DepartureAnchoringOp_ReqReceived',
    'DepartureAnchoringOp_Requested',
    'DepartureAnchoringOp_Cancelled',
];
