<template>
    <mf-box border-box without-inner-paddings>
        <mf-checkbox slot="header" class="filter__checkbox" @change="toggleAll" :checked="allOptionsSelected">
            Vessel Type
        </mf-checkbox>
        <div>
            <template v-for="(vesselLine, index) in options">
                <mf-divider :key="`divider-${index}`" />
                <div :key="`line-${index}`" class="filter__items">
                    <div class="filter__item filter__item_half" v-for="vessel in vesselLine" :key="vessel.name">
                        <mf-checkbox
                            class="filter__checkbox"
                            @change="vessel.checked = $event"
                            :checked="vessel.checked"
                        >
                            {{ vessel.name }}
                        </mf-checkbox>
                    </div>
                </div>
            </template>
            <mf-divider />
        </div>
    </mf-box>
</template>

<script>
    export default {
        name: 'VesselTypeBox',
        props: {
            options: {
                type: Array,
                required: true,
            },
            allOptionsSelected: {
                type: Boolean,
                required: true,
            },
        },
        methods: {
            toggleAll() {
                this.$emit('toggleAll');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .filter__item {
        padding: $space-14 $space-16;
    }

    .filter__items {
        display: flex;
    }

    .filter__item_half {
        flex: 1 1 auto;
        width: 50%;
        flex-basis: 50%;
    }
</style>
