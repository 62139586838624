<template>
    <mf-modal v-if="isOpen" @close="close">
        <div slot="header">
            Filter Options
        </div>
        <div class="filter__container" v-if="filters">
            <div class="filter__column">
                <div class="filter__row">
                    <div class="filter__column">
                        <stages-box
                            :options="filters.stage"
                            :all-options-selected="allOptionsSelected('stage')"
                            @toggleAll="toggleAll('stage')"
                        />
                    </div>
                    <div class="filter__column">
                        <statuses-box
                            :options="filters.status"
                            :all-options-selected="allOptionsSelected('status')"
                            @toggleAll="toggleAll('status')"
                        />
                    </div>
                    <div class="filter__column filter__column_large">
                        <vessel-type-box
                            :options="filters.vesselType"
                            :all-options-selected="allOptionsSelected('vesselType')"
                            @toggleAll="toggleAll('vesselType')"
                        />
                    </div>
                </div>
            </div>
            <div class="filter__column filter__column_small">
                <div class="filter__row">
                    <div class="filter__column filter__column_large">
                        <time-box
                            :options="filters.time"
                            @afterChanged="filters.time.before = $event"
                            @beforeChanged="filters.time.after = $event"
                        />
                    </div>
                    <div class="filter__column filter__column_large">
                        <custom-lists
                            type="vessel"
                            :options="filters.vessel"
                            :all-options-selected="allOptionsSelected('vessel')"
                            @toggleAll="toggleAll('vessel')"
                        />
                    </div>
                    <div class="filter__column filter__column_large">
                        <custom-lists
                            type="quay"
                            :options="filters.quay"
                            :all-options-selected="allOptionsSelected('quay')"
                            @toggleAll="toggleAll('quay')"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="filter__buttons">
                <mf-button class="filter__button" @click="setDefaults">Defaults</mf-button>
                <mf-button class="filter__button" color="blue" @click="apply">Apply</mf-button>
            </div>
        </div>
    </mf-modal>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { clone, cloneDeep } from 'lodash';
    import StagesBox from '@/modals/filter-modal-components/StagesBox';
    import StatusesBox from '@/modals/filter-modal-components/StatusesBox';
    import VesselTypeBox from '@/modals/filter-modal-components/VesselTypeBox';
    import TimeBox from '@/modals/filter-modal-components/TimeBox';
    import CustomLists from '@/modals/filter-modal-components/CustomLists';

    export default {
        name: 'FilterPortcallsModal',
        components: { TimeBox, VesselTypeBox, StatusesBox, StagesBox, CustomLists },
        props: {
            isOpen: {
                type: Boolean,
                required: true,
            },
        },
        mounted() {
            this.$store.dispatch('getVesselTypes');
        },
        computed: {
            ...mapGetters(['vesselTypes', 'portcallFilters']),
        },
        data() {
            return {
                filters: null,
            };
        },
        watch: {
            vesselTypes(val) {
                val && this.generateVesselTypeOptions();
            },
            portcallFilters: {
                handler(val) {
                    this.filters = cloneDeep(val);
                    if (!val.vesselType.length) {
                        this.generateVesselTypeOptions();
                    }
                },
                immediate: true,
            },
        },
        methods: {
            toggleAll(optionName) {
                const currentState = this.allOptionsSelected(optionName);
                this.filters[optionName].map(value => {
                    value instanceof Array
                        ? value.map(innerValue => {
                              innerValue.checked = !currentState;
                              return innerValue;
                          })
                        : (value.checked = !currentState);
                    return value;
                });
            },
            allOptionsSelected(optionName) {
                return this.filters[optionName].every(value => {
                    return value instanceof Array ? value.every(innerValue => innerValue.checked) : value.checked;
                });
            },
            generateVesselTypeOptions() {
                if (!this.portcallFilters.vesselType.length) {
                    this.filters.vesselType = clone(this.vesselTypes)
                        .map(name => ({ name, checked: true }))
                        .reduce((result, value, index, array) => {
                            if (!(index % 2)) {
                                result.push(array.slice(index, index + 2));
                            }
                            return result;
                        }, []);
                }
            },
            close() {
                this.$emit('close');
                this.filters = cloneDeep(this.portcallFilters);
            },
            setDefaults() {
                this.$store.commit('setDefaultsPortCallsFilters');
            },
            apply() {
                this.$store.commit('setPortCallsFilters', this.filters);
                this.$store.dispatch('getPortCalls');
                this.close();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .filter__container {
        display: flex;
        margin: (-$space-8) 0 (-$space-8) (-$space-8);
        flex-wrap: wrap;
        @include media($lg) {
            flex-wrap: nowrap;
        }
    }

    .filter__row {
        display: flex;
        margin: (-$space-8);
        flex-wrap: wrap;
    }

    .filter__column {
        flex: 1 1 auto;
        padding: $space-8;
        box-sizing: border-box;
        width: 100%;
        flex-basis: 100%;
        @include media($sm) {
            width: auto;
            flex-basis: auto;
        }
    }

    .filter__column_small {
        @include media($lg) {
            width: 33.33%;
            flex-basis: 33.33%;
        }
    }

    .filter__column_large {
        width: 100%;
        flex-basis: 100%;
    }

    .filter__buttons {
        display: flex;
        justify-content: flex-end;
    }

    .filter__button:not(:first-child) {
        margin-left: $space-8;
    }
</style>
